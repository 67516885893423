import { FlexibleContent } from '@/templates';
import { HeroSlider } from '@/templates/hero-slider/HeroSlider';
import { getBaseLayout } from '@/layouts/BaseLayout';

import getInitialProps from '@/lib/graphql/api/getInitialProps';
import {
  FlexibleContentBlocks,
  pagePropsPartial,
  sliderHero,
} from '@/lib/graphql/partials';
import { PageQuery } from '@/lib/graphql/querys/PageQuery';
import { pagePropsServerTransformer } from '@/lib/graphql/transformers';
import { routes } from '@/lib/routes';

import type { NextPageWithLayout } from './_app';

// TODO: Añadir tipos
const Home: NextPageWithLayout = ({ page: { hero, flexibleContent } }: any) => (
  <>
    <HeroSlider hero={hero} />
    <FlexibleContent type="Page" flexible={flexibleContent.flexible} />
  </>
);

export async function getStaticProps() {
  const hero = `
    page(id: $slug, idType: URI) {
      flexibleContent: ${FlexibleContentBlocks('Page')}
      hero: ${sliderHero('Page')}
    }
  `;
  const Query = PageQuery(pagePropsPartial, hero);

  return await pagePropsServerTransformer({
    staticProps: await getInitialProps(Query, { slug: 'portada' }),
    type: 'Page',
    path: routes.index,
  });
}

Home.getLayout = getBaseLayout;

export default Home;
